import React, { useState, useEffect, useMemo, useRef } from 'react';
import Map, { Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { TiLocationArrow } from "react-icons/ti";
import { FaLocationDot } from "react-icons/fa6";
import { MdOpenInFull } from "react-icons/md";
import { MdCloseFullscreen } from "react-icons/md";
import spotsData from '../data/data.json';
import spotsData4 from '../data/data4.json';

const MapComponent = ({ location, isDarkMode = false, onMapClick, surfData, windData, hoverIndex, onSpotClick }) => {
  const [viewState, setViewState] = useState({
    latitude: 45,
    longitude: 0,
    zoom: 5,
  });
  const [marker, setMarker] = useState(null);
  const [visibleSpots, setVisibleSpots] = useState([]);
  const [isMapExtended, setIsMapExtended] = useState(false);
  const [mapHeight, setMapHeight] = useState(0);

  const mapContainerRef = useRef(null);

  const allSpotsData = useMemo(() => [...spotsData, ...spotsData4], []);

  const clampCoordinates = (lng, lat) => {
    const clampedLng = ((lng + 180) % 360 + 360) % 360 - 180;
    const clampedLat = Math.max(-90, Math.min(90, lat));
    return { longitude: clampedLng, latitude: clampedLat };
  };

  const handleSpotClick = (spot) => {
    if (onSpotClick) {
      onSpotClick(spot);
    }
  };

  useEffect(() => {
    if (location) {
      setViewState({
        longitude: location.longitude,
        latitude: location.latitude,
        zoom: viewState.zoom,
      });

      setMarker({
        longitude: location.longitude,
        latitude: location.latitude,
      });
    }
  }, [location]);

  const mapStyle = isDarkMode
    ? 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json'
    : 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json';

  const handleMapClick = (event) => {
    const { lng, lat } = event.lngLat;
    const clampedCoords = clampCoordinates(lng, lat);
    setMarker(clampedCoords);
    if (onMapClick) onMapClick(clampedCoords.longitude, clampedCoords.latitude);
  };

  const Arrow = ({ angle, colorClass }) => (
    <span
      className={`inline-block transform ${colorClass}`}
      style={{ transform: `translateY(3px) translateX(0px) rotate(${angle - 45 - 180}deg) translate(-18px,18px)`, fontSize: 52 }}
    >
      <TiLocationArrow />
    </span>
  );

  const renderMarker = () => {
    if (!marker) return null;
  
    if (!surfData || surfData.length === 0 || hoverIndex === null || !windData) {
      return (
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <div className="z-50">
            <FaLocationDot size={30} />
          </div>
        </Marker>
      );
    }
  
    const currentData = surfData[hoverIndex];
    const currentWindDirection = windData.hourly.wind_direction_10m[hoverIndex];
  
    return (
      <>
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <div className="z-50 w-2 h-2 bg-zinc-900/[.6] dark:bg-zinc-100/[.6] rounded-full"></div>
        </Marker>
  
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <div className="z-50">
            <Arrow
              angle={currentData.wave_direction || 0}
              colorClass="text-amber-500/[.8] dark:text-amber-400/[.8]"
            />
          </div>
        </Marker>
  
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <div className="z-50">
            <Arrow
              angle={currentWindDirection || 0}
              colorClass="text-blue-500/[.85] dark:text-blue-400/[.9]"
            />
          </div>
        </Marker>
      </>
    );
  };

  const filterSpots = (bounds, maxSpots = 68) => {
    return allSpotsData.filter(spot => {
      const lng = parseFloat(spot.longitude);
      const lat = parseFloat(spot.latitude);
      return lng >= bounds.west && lng <= bounds.east && lat >= bounds.south && lat <= bounds.north;
    }).slice(0, maxSpots);
  };

  useEffect(() => {
    const initialZoomFactor = Math.max(0.15, 10 - viewState.zoom);
    const initialBounds = { 
      north: viewState.latitude + (initialZoomFactor * 2.1), 
      south: viewState.latitude - (initialZoomFactor * 2.1),
      east: viewState.longitude + (initialZoomFactor * 2.3),
      west: viewState.longitude - (initialZoomFactor * 2.3),
    };
    setVisibleSpots(filterSpots(initialBounds)); 
  }, []); 

  useEffect(() => {
    if (viewState.latitude && viewState.longitude) {
      const zoomFactor = Math.max(0.15, 10 - viewState.zoom);
      const bounds = {
        north: viewState.latitude + (zoomFactor * 2.3),
        south: viewState.latitude - (zoomFactor * 2.3),
        east: viewState.longitude + (zoomFactor * 2.3),
        west: viewState.longitude - (zoomFactor * 2.3),
      };
      setVisibleSpots(filterSpots(bounds));
    }
  }, [viewState, allSpotsData]);

  useEffect(() => {
    const updateMapHeight = () => {
      if (mapContainerRef.current) {
        setMapHeight(mapContainerRef.current.offsetHeight);
      }
    };
  
    updateMapHeight();
  
    const resizeObserver = new ResizeObserver(updateMapHeight);
  
    if (mapContainerRef.current) {
      resizeObserver.observe(mapContainerRef.current);
    }
  
    return () => {
      if (mapContainerRef.current) {
        resizeObserver.unobserve(mapContainerRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const toggleMapExtension = () => {
    setIsMapExtended(!isMapExtended);
  };

  return (
    <div
      ref={mapContainerRef}
      className={`relative rounded-sm shadow-sm overflow-hidden flex-grow ${isMapExtended ? 'min-h-[62dvh]' : 'min-h-32'}`}
    >
      <Map
        mapLib={maplibregl}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        onClick={handleMapClick}
        style={{ width: '100%', height: '100%' }}
        mapStyle={mapStyle}     
      >
        {visibleSpots.map((spot) => (
          <Marker
            key={spot.id}
            longitude={parseFloat(spot.longitude)}
            latitude={parseFloat(spot.latitude)}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              handleSpotClick({
                latitude: parseFloat(spot.latitude),
                longitude: parseFloat(spot.longitude),
                text: spot.text
              });
            }}
          >
           <div className="cursor-pointer text-zinc-500 dark:text-zinc-300 transform -translate-y-2">
            <FaLocationDot size={20} />
          </div>
        </Marker>
      ))}
      {renderMarker()}
      {location && mapHeight <= window.innerHeight * 0.64 && (
        <div className="absolute -top-2 -right-2 z-10">
          <div
            onClick={toggleMapExtension}
            className="p-3"
            style={{ cursor: 'pointer', background: 'transparent' }}
            title={isMapExtended ? "Shrink map" : "Extend map"}
          >
            <button
              className="bg-zinc-200 dark:bg-zinc-800 p-2 rounded-full shadow-md"
              style={{ pointerEvents: 'none' }}
            >
              {isMapExtended ? (
                <MdCloseFullscreen size={21} style={{ transform: 'rotate(-45deg)' }} />
              ) : (
                <MdOpenInFull size={21} style={{ transform: 'rotate(-45deg)' }} />
              )}
            </button>
          </div>
        </div>
      )}
    </Map>
  </div>
);
};

export default MapComponent;