import React, { useState, useEffect, useCallback } from 'react';
import App from '../src/App';

export { Page };

function Page({ initialData, initialCoordinates, initialTideData, locationText, initialWindData }) {
  const [coordinates, setCoordinates] = useState(initialCoordinates || []);
  const [data, setData] = useState(initialData);
  const [tideData, setTideData] = useState(initialTideData);
  const [windData, setWindData] = useState(initialWindData); // Add windData state
  const [ws, setWs] = useState(null);
  const [locationName, setLocationName] = useState(locationText);

  const createWebSocket = useCallback(() => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = window.location.host;
    return new WebSocket(`${protocol}//${host}`);
  }, []);

  const connectWebSocket = useCallback(() => {
    const socket = createWebSocket();

    socket.onopen = () => {
      console.log('WebSocket connected');
    };

    socket.onmessage = (event) => {
      const { initialData, tideData, windData, error } = JSON.parse(event.data); // Include windData
      if (error) {
        console.error(error);
      } else {
        if (initialData) setData(initialData);
        if (tideData) setTideData(tideData);
        if (windData) setWindData(windData); // Update windData
      }
    };

    socket.onclose = (event) => {
      console.log('WebSocket disconnected. Attempting to reconnect...');
      setTimeout(() => connectWebSocket(), 7000); // Attempt to reconnect after 7 seconds
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      socket.close(); // This will trigger the onclose event and attempt a reconnection
    };

    setWs(socket);

    return socket;
  }, [createWebSocket]);

  useEffect(() => {
    let socket = connectWebSocket();
    let pingInterval;

    // Add ping mechanism to keep the connection alive
    pingInterval = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: 'ping' }));
      }
    }, 30000); // Send a ping every 30 seconds

    return () => {
      clearInterval(pingInterval);
      socket.close();
    };
  }, [connectWebSocket]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lat = urlParams.get('lat');
    const lon = urlParams.get('lon');
    if (lat && lon) {
      setCoordinates([parseFloat(lat), parseFloat(lon)]);
      if (!initialData) {
        handleLocationChange(parseFloat(lat), parseFloat(lon));
      }
    }
  }, []);

  const handleLocationChange = (newLat, newLong) => {
    setCoordinates([newLat, newLong]);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ lat: newLat, lon: newLong }));
    } else {
      console.log('WebSocket not connected. Reconnecting...');
      const newSocket = connectWebSocket();
      newSocket.onopen = () => {
        newSocket.send(JSON.stringify({ lat: newLat, lon: newLong }));
      };
    }
    window.history.pushState({}, '', `?lat=${newLat}&lon=${newLong}`);
  };

  const onSearch = ({ latitude, longitude }) => {
    handleLocationChange(latitude, longitude);
  };
  
  return (
    <App 
      initialData={data} 
      initialTideData={tideData}
      windData={windData} // Pass windData to App
      latitude={coordinates[0]} 
      longitude={coordinates[1]} 
      onLocationChange={handleLocationChange}
      onSearch={onSearch}
      locationName={locationName}
    />
  );
}