// /src/utils/timeUtils.js

const getAdjustedTime = (baseTime, referenceDate) => {
  const adjustedTime = new Date(baseTime);

  // Force the use of UTC for comparison
  if (adjustedTime.getUTCDate() !== referenceDate.getUTCDate()) {
    adjustedTime.setUTCFullYear(
      referenceDate.getUTCFullYear(),
      referenceDate.getUTCMonth(),
      referenceDate.getUTCDate()
    );
  }
  return adjustedTime.getTime(); // This will return the timestamp in UTC
};

  
  const isTimeInRange = (date, sunTimes, startKey, endKey) => {
    if (!sunTimes) return false;
    const dateString = date.toDateString();
    const dayTimes = sunTimes[dateString];
    if (!dayTimes) {
      console.warn(`No sun times data for ${dateString}`);
      return false;
    }
  
    const time = date.getTime();
    const startTime = getAdjustedTime(new Date(dayTimes[startKey]).getTime(), date);
    const endTime = getAdjustedTime(new Date(dayTimes[endKey]).getTime(), date);
  
    return time >= startTime && time <= endTime;
  };
  
  export const isNightTime = (date, sunTimes) => 
    sunTimes ? !isTimeInRange(date, sunTimes, 'nightEnd', 'night') : false;
  
  export const isDuskTime = (date, sunTimes) => 
    sunTimes ? isTimeInRange(date, sunTimes, 'sunsetStart', 'night') : false;
  
  export const isDawnTime = (date, sunTimes) => 
    sunTimes ? isTimeInRange(date, sunTimes, 'nightEnd', 'sunriseEnd') : false;
  
  export const getSunriseTime = (sunTimes, date) => {
    const dateString = date.toDateString();
    return sunTimes[dateString]?.sunrise;
  };
  
  export const getSunsetTime = (sunTimes, date) => {
    const dateString = date.toDateString();
      return sunTimes[dateString]?.sunset;
  
  };