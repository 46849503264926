import React, { useState, useEffect } from 'react';
import Downshift from 'downshift';
import data from '../data/data.json';
import data4 from '../data/data4.json';
import { MdCancel } from "react-icons/md";

const SearchBar = ({ onSearch, setHoveredLocation, searchQuery }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(searchQuery || '');

  useEffect(() => {
    const combinedData = [
      ...data.map(item => ({ ...item, source: 'surfspot' })),
      ...data4.map(item => ({ ...item, source: 'town' }))
    ];
    setOptions(combinedData);
  }, []);

  useEffect(() => {
    setInputValue(searchQuery); // Update inputValue when searchQuery changes
  }, [searchQuery]);

  const handleSelection = (selectedItem) => {
    if (selectedItem) {
      onSearch(selectedItem);
    }

  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="bg-yellow-300 dark:bg-yellow-700">{part}</span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <Downshift
      itemToString={item => (item ? `${item.text} (${item.source})` : '')}
      onChange={handleSelection}
      inputValue={inputValue}
      onInputValueChange={setInputValue}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        clearSelection,
      }) => {
        const filteredOptions = options.filter(item =>
          item.text.toLowerCase().includes(inputValue.toLowerCase())
        ).slice(0, 50);

        return (
          <div className="relative w-full">
            <div className="relative">
              <input
                {...getInputProps({
                  placeholder: 'Search location',
                  className: "w-full px-4 py-2 bg-white dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-600 text-black dark:text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400",
                })}
              />
              {inputValue.length > 0 && (
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black dark:text-white text-2xl bg-white dark:bg-zinc-800 rounded-xl"
                  onClick={() => {
                    clearSelection();
                    setInputValue('');
                    onSearch(null); // Clear search result
                    setHoveredLocation(null); // Clear hovered location
                  }}
                >
                  <MdCancel size={28} />
                </button>
              )}
            </div>
            {inputValue.length > 0 && (
              <ul
                {...getMenuProps()}
                className={`absolute w-full max-h-60 overflow-y-auto mt-1 rounded shadow-lg z-10 ${isOpen ? 'border border-zinc-300 dark:border-zinc-600' : ''} bg-white dark:bg-zinc-700`}
              >
                {isOpen &&
                  filteredOptions.map((item, index) => (
                    <li
                      key={item.id}
                      {...getItemProps({
                        index,
                        item,
                        className: `px-4 py-2 cursor-pointer ${highlightedIndex === index ? 'bg-blue-100 dark:bg-blue-600 text-black dark:text-white' : 'hover:bg-blue-50 dark:hover:bg-blue-800 text-black dark:text-white'}`,
                        onMouseEnter: () => setHoveredLocation(item),
                        onMouseLeave: () => setHoveredLocation(null)
                      })}
                    >
                      {highlightText(item.text, inputValue)} ({item.source})
                    </li>
                  ))}
              </ul>
            )}
            <div className='h-1'></div>
          </div>
        );
      }}
    </Downshift>
  );
};

export default SearchBar;
