// SurfOverview.jsx
import React, { useMemo, useCallback, useEffect } from 'react';

const SurfOverview = ({ surfInfoData, windData, onHoverIndex, hoverIndex, isServer, closestTimeIndex, sunTimes }) => {
  if (!surfInfoData || surfInfoData.length === 0 || !windData) {
    return <div>Loading Wave Energy and Wind Gusts Data...</div>;
  }

  const formatDate = (date) => {
    const utcDate = new Date(date);
    return utcDate.toLocaleDateString('en-US', { timeZone: 'UTC', month: 'numeric', day: 'numeric' });
  };

  const groupedEnergies = useMemo(() => {
    return surfInfoData.reduce((acc, item) => {
      const date = formatDate(item.date);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  }, [surfInfoData]);

  const uniqueDays = useMemo(() => Object.keys(groupedEnergies), [groupedEnergies]);

  useEffect(() => {
    if (!isServer && closestTimeIndex !== undefined) {
      onHoverIndex(closestTimeIndex, true);
      Promise.resolve().then(() => {
        onHoverIndex(closestTimeIndex, false);
      });
    }
  }, [isServer, closestTimeIndex, onHoverIndex]);

  const handleMouseEnter = useCallback((index) => {
    if (!isServer) {
      window.requestAnimationFrame(() => onHoverIndex(index, true));
    }
  }, [onHoverIndex, isServer]);

  const handleMouseLeave = useCallback(() => {
    if (!isServer) {
      setTimeout(() => {
        onHoverIndex(hoverIndex, false);
      }, 550);
    }
  }, [onHoverIndex, hoverIndex, isServer]);

  const handleTouchStart = useCallback((index) => {
    if (!isServer) {
      onHoverIndex(index, true);
    }
  }, [onHoverIndex, isServer]);

  const handleTouchMove = useCallback((event) => {
    if (!isServer) {
      const touch = event.touches[0];
      const container = event.currentTarget;

      if (container) {
        const rect = container.getBoundingClientRect();
        const touchX = touch.clientX - rect.left;
        const index = Math.floor((touchX / rect.width) * surfInfoData.length);

        if (index >= 0 && index < surfInfoData.length) {
          onHoverIndex(index, true);
        }
      }
    }
  }, [onHoverIndex, surfInfoData.length, isServer]);

  const handleTouchEnd = useCallback(() => {
    if (!isServer) {
      setTimeout(() => {
        onHoverIndex(hoverIndex, false);
      }, 550);
    }
  }, [onHoverIndex, hoverIndex, isServer]);

  return (
    <div 
      className="mt-1 rounded-sm bg-zinc-100 dark:bg-zinc-800"
      onMouseLeave={handleMouseLeave}
    >
      {/* Days header */}
      <div className="flex px-0 py">
        {uniqueDays.map((day, index) => {
          const dayData = groupedEnergies[day];
          const totalBars = dayData.length;
          const flexBasis = `${(totalBars / surfInfoData.length) * 100}%`;

          return (
            <div key={index} style={{ flexBasis }} className="flex justify-center relative">
              <span className="text-xs font-semibold">{day}</span>
            </div>
          );
        })}
      </div>

      {/* Energy and Wind Gusts bars */}
      <div 
        className="flex overflow-x-auto relative touch-none"
        onTouchMove={isServer ? undefined : handleTouchMove}
        onTouchEnd={isServer ? undefined : handleTouchEnd}
      >
        {surfInfoData.map((item, index) => (
          <React.Fragment key={index}>
            <div
              className="relative flex-grow"
              onMouseEnter={isServer ? undefined : () => handleMouseEnter(index)}
              onTouchStart={isServer ? undefined : () => handleTouchStart(index)}
            >
               <div
    className={`absolute inset-0 ${
      index === closestTimeIndex || index === hoverIndex
        ? 'bg-pink-400/[.3] md:bg-pink-400/[.15] -right-[150%] -left-[100%] md:-right-[50%] md:-left-[50%] z-10'
        : 'hidden'
    }`}
    style={{ height: '7rem' }}
  />
              <div
                className={`absolute inset-0 ${
                  index === closestTimeIndex
                    ? 'bg-pink-400/[.7] z-20'
                    : index === hoverIndex
                    ? 'bg-red-400/[.7] z-30'
                    : 'z-0'
                }`}
                style={{ height: '7rem' }}
              />
              <div className="relative w-full" style={{ height: '7rem' }}>
                <div
                  className="absolute bottom-0 left-0 w-full bg-yellow-500 dark:bg-yellow-600"
                  style={{
                    height: `${(Math.pow(Math.min(item.energy, 2200) / 2200, 0.7)) * 100}%`,
                    borderRadius: 0
                  }}
                />
                <div
                  className="absolute bottom-0 left-0 w-full bg-blue-500/[.4] dark:bg-blue-500/[0.55]"
                  style={{ height: `${(Math.pow(Math.min(windData.hourly.wind_gusts_10m[index], 75) / 75, 1.35)) * 100}%`, borderRadius: 0 }}
                />
                {item.isNight && (
                  <div className="absolute inset-0 bg-zinc-400/[.2] dark:bg-zinc-950/[.2] z-20" />
                )}
                {item.isDusk && (
                  <div className="absolute inset-0 bg-zinc-400/[.1] dark:bg-zinc-950/[.1] z-20" />
                )}
                {item.isDawn && (
                  <div className="absolute inset-0 bg-zinc-400/[.1] dark:bg-zinc-950/[.1] z-20" />
                )}
              </div>
            </div>

            {new Date(item.date).getUTCHours() === 23 && index < surfInfoData.length - 1 && (
              <div
                className="absolute w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.8] h-full z-40"
                style={{ left: `${((index + 1) / surfInfoData.length) * 100}%`, zIndex: 10 }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SurfOverview;