import React from 'react';

const SpotInfo = ({ selectedLocation, locationName, latitude, longitude, timezone, utcOffset }) => {
    
    // Function to calculate local time based on UTC offset
    const getLocalTime = () => {
        const now = new Date();
        const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);
        const localTime = new Date(utcTime + (utcOffset * 1000));
        return localTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const localTime = getLocalTime();

    return (
        <div className="mt-1 mb-0.5">
            {selectedLocation ? (
                <div className="space-y-0.5">
                    <h1 className="text-2xl font-bold text-zinc-800 dark:text-zinc-100">
                        {selectedLocation.text}
                    </h1>
                    
                    <p className="text-xs sm:text-sm text-zinc-500 dark:text-zinc-400">
                        Lat {selectedLocation.latitude.toFixed(4)} Lon {selectedLocation.longitude.toFixed(4)} &nbsp; {timezone} {localTime}
                    </p>
   
                </div>
            ) : locationName ? (
                <div className="space-y-0.5">
                    <h1 className="text-2xl font-bold text-zinc-800 dark:text-zinc-100">
                        {locationName}
                    </h1>
                    <p className="text-xs sm:text-sm text-zinc-500 dark:text-zinc-400">
                        Lat {latitude.toFixed(4)} Lon {longitude.toFixed(4)} &nbsp; {timezone} {localTime}
                    </p>

                </div>
            ) : null}
        </div>
    );
};

export default SpotInfo;