import React from 'react';
import { MdOutlineLightMode, MdOutlineDarkMode, MdInfoOutline } from "react-icons/md";

const Header = ({ toggleDarkMode, darkMode, toggleInfo }) => {
  const handleLogoClick = () => {
    window.location.href = window.location.pathname;
  };

  return (
    <header className="flex justify-between items-center pl-px py-4 bg-white dark:bg-zinc-900 text-zinc-800 dark:text-zinc-200">  
      <div className="h-9 w-auto cursor-pointer" onClick={handleLogoClick}>        
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 15"
          className="h-full w-3/5 fill-current items-start"
        >
                   <g>
            <path d="M88.4,6.5c-.1,0-.2-.1-.3-.2-.1-.1-.3-.2-.4-.4-.7-1.9-2.1-3.3-3.6-4.5-.5-.4-1-.6-1.5-.7-.6,0-1.2-.2-1.6-.7,0,0-.2-.1-.4-.1-.2,0-.5,0-.7.1-.5.2-.9.4-1.3.5-.8.3-1.5.4-2.2-.2-.3-.2-.7-.2-.9.2-.1.3-.2.6-.2.9,0,.3,0,.5-.3.6-.2.1-.3.3-.5.4-.3.2-.7.3-.9.5s-.4.6-.6,1c-.2.3-.4.5-.5.8-.2.4-.2,1,.3,1.4.1,0,.2.2.3.2.2.2.2.3,0,.4s-.4.2-.6.3c-.3.1-.4.3-.4.6,0,.6,0,1.1,0,1.6,0,.6,0,1.3.3,1.9,0,0,0,.2,0,.3v1.1c0,.5.2.9.7,1.1.1,0,.3.1.4.2.4.2.4.3.1.6-.3.3-.3.3,0,.7.5.6,1.1,1.1,1.8,1.4,1.5.7,3,1.1,4.6.9h0c.6,0,1.1,0,1.7,0,.4,0,.9-.2,1.3-.3.9-.3,1.8-.6,2.5-1.3.5-.5.9-1.1,1.6-1.3.3,0,.3-.3,0-.6s-.3-.3,0-.6c.2-.4.3-.8.5-1.3.2-.8.2-1.7.6-2.4.2-.5.2-.9,0-1.4-.2-.7-.2-.8.4-1.2.3-.2.4-.5,0-.7ZM84.7,7.2c-.1.1-.2.2-.4.3-.5.4-.7,1.4-.5,2,0,.1.2.3.3.4.3,0,.3.3.4.6,0,.2,0,.5,0,.7h0c0,.5,0,1.1-.2,1.6-.2.5-.4.9,0,1.4,0,0,0,.2,0,.2-.2,0-.3.2-.5.2-.6.1-1.2.5-1.6.9-.2.2-.5.4-.7.6-.3.3-.7.3-1.1.2-.2,0-.5,0-.8,0s-.4,0-.6,0c-.6-.2-.9-.7-1.3-1.1-.2-.2,0-.3.1-.5.3-.3.3-.5-.1-.7,0,0-.1,0-.2,0-.6,0-.8-.5-.9-1-.2-1.1-.3-2.1-.2-3.2,0-.6.1-1.1.1-1.7,0-.3.2-.6.5-.7,0,0,0,0,0,0,.5-.3.5-.5,0-.8-.5-.2-.6-.5-.6-1,0-.3,0-.6,0-.9.1-.7.4-1.2,1-1.7,1.1-.8,2.3-1.3,3.7-1.2.7,0,1.2.2,1.7.7.2.2.3.3.2.5,0,.5.1.9.4,1.2,0,0,.2.2.2.3.4.5.4.7.1,1.2,0,0,0,0,0,0-.2.3-.2.4.2.5,0,0,.2,0,.3.1.1,0,.3,0,.4.2.5.2.5.3.1.7Z"/>
            <path d="M16.4,6.2s-.1,0-.2,0c-.6-.2-.8-.7-1.1-1.2-.2-.5-.5-.9-.8-1.3-.5-.6-1.1-1.2-1.8-1.8-.6-.6-1.4-1-2.2-1.1-.4,0-.9-.1-1.2-.5-.3-.3-.7-.3-1.1-.2-.3.1-.6.2-1,.3-.4.1-.7.3-1.1.4-.5.1-1.1.2-1.5-.2-.3-.3-.6-.3-.8,0-.3.2-.5.5-.4.9.1.5-.1.8-.5,1.1-.3.2-.6.3-.8.4-.1,0-.3.2-.4.3-.3.4-.6.9-.8,1.3-.1.2-.3.5-.3.7-.1.4,0,.6.3.8.1,0,.3.1.4.2.3.2.3.3,0,.5-.2.1-.3.2-.5.3-.3.2-.5.4-.5.8,0,1,0,1.9,0,2.9,0,.2,0,.4.1.6.4.9.9,1.8,1.3,2.6,0,.2.1.4,0,.6-.3.3-.2.6,0,.9.2.2.3.4.5.6.4.3.9.6,1.4.9,1.4.7,2.9,1,4.5.9h0c1,0,1.9,0,2.9-.3,1.3-.4,2.4-1,3.2-2,.3-.3.6-.6.9-.9.2-.1.3-.3.2-.5-.2-.4-.2-.7,0-1,0,0,0-.2,0-.3.1-.7.2-1.5.4-2.2,0-.4.1-.9.3-1.3.2-.4.2-.8,0-1.3-.2-.6-.2-.7.4-1,0,0,0,0,.1,0,.3-.2.3-.4,0-.6ZM12.8,6.7c-.1,0-.2.1-.3.2-.1,0-.3.2-.3.3,0,.3-.2.6-.3.8-.2.5-.1,1,0,1.4,0,.1.2.2.4.3.2,0,.2.2.3.4.2,1,0,2-.3,2.9,0,.2,0,.4,0,.6.3.3.3.4-.1.6-.1,0-.2.1-.4.1-.7.2-1.4.6-1.9,1.2-.1.1-.3.2-.4.4-.4.3-.8.5-1.3.3-.2,0-.4,0-.6,0-.3,0-.5,0-.7,0-.5-.2-.9-.5-1.3-.9-.3-.3-.2-.5,0-.8.2-.3.2-.4,0-.6-.2-.1-.4-.2-.6-.3-.2-.1-.4-.3-.4-.5-.3-1.2-.3-2.5-.2-3.7,0-.5,0-1,0-1.4,0-.5.1-.8.6-1.1.5-.2.5-.5,0-.8-.4-.2-.5-.4-.5-.8,0-.4,0-.8.1-1.2,0-.4.3-.8.7-1.1.8-.6,1.7-1,2.6-1.1.3,0,.7,0,.9-.1h0c.7,0,1.3.1,1.8.5.2.2.4.4.4.7,0,.4.2.8.4,1.1,0,.2.3.4.4.6.2.3.2.7.1,1.1-.1.3,0,.4.2.5.2,0,.4,0,.7.2.3.1.3.2,0,.4Z"/>
            <path d="M101.5,14.1c0,0-.1-.1-.2-.1-.4-.2-.6-.6-.7-1,0-.2,0-.4,0-.6,0-.8-.5-1.4-1.2-1.7-.2,0-.4-.2-.6-.3-.2-.1-.3-.3-.5-.5,0,0-.1-.2,0-.3.5-.3.9-.8,1.2-1.3.3-.4.6-.7,1-.8.2,0,.5-.2.5-.4,0-.1-.2-.3-.4-.4-.3-.2-.4-.3-.5-.6,0-.5-.4-1-.8-1.3-.8-.5-1.5-1.1-2.3-1.6-.2-.1-.4-.2-.6-.2-.8,0-1.6-.1-2.4,0-1.1,0-2.3.1-3.4,0-.4,0-.8,0-1.3,0-.4,0-.8.4-.9.8,0,.3.2.6.6.8.2,0,.3.2.5.2s.2.2.2.3c-.2.7,0,1.3.6,1.8.3.3.3.4,0,.6-.3.2-.5.4-.5.8,0,.7,0,1.3.1,2,0,1,.1,1.9.2,2.9,0,.5,0,.9.5,1.2.2,0,.2.3,0,.5-.2.1-.3.2-.5.3-.2.1-.5.3-.7.4-.5.3-.6.8-.5,1.2.2.5.5.6,1,.7.4,0,.8,0,1,0h0c1.2,0,2.2-.1,3.3-.2.2,0,.3,0,.3-.3,0-.3,0-.5-.2-.7-.1-.2-.3-.3-.5-.5-.3-.3-.2-.3,0-.5.1,0,.3-.1.4-.2.2-.2.2-.4,0-.6,0,0-.2,0-.3-.1-.3-.1-.4-.4-.4-.7,0-1.7,0-3.4,0-5.1,0-.4.1-.6.4-.7.2,0,.5-.2.4-.5,0-.2-.2-.3-.4-.4,0,0-.2,0-.2-.2,0,0-.2-.2-.2-.3,0-.5,0-1,0-1.5,0-.5.3-.7.7-.7.3,0,.6,0,.9,0,.7.1,1.3.8,1.4,1.4,0,.6.2,1.2.9,1.5.2,0,.2.3,0,.5-.1.1-.3.2-.5.3-.1,0-.3.2-.3.3-.2.3-.4.6-.8.7-.3.2-.7.3-1,.5-.2.1-.4.2-.6.4-.3.3-.3.7.1.9,0,0,.2,0,.3.1.4,0,.7.3,1,.6.3.3.7.6,1,1,.3.4.6.7.9,1.1.2.4.5.8,1,1,0,0,0,0,0,0,.3.2.3.3,0,.5-.3.2-.6.4-.9.7-.2.2-.5.4-.7.6-.5.5-.3.8.2,1.1,0,0,0,0,0,0,1.1.4,2.2.4,3.4.2.1,0,.2,0,.4-.2.4-.2.5-.5.2-.9,0-.2-.2-.4-.3-.5-.2-.3-.1-.4.2-.6.1,0,.3-.1.4-.2.3-.2.3-.4,0-.6Z"/>
            <path d="M70.8,3c-.3-.2-.7-.3-1.1-.3-.9,0-1.8,0-2.7,0s-1.2,0-1.8,0c-.1,0-.4,0-.4.2-.2.4-.2.8,0,1.2.1.3.4.2.7.2h.1c.3,0,.4,0,.4.4,0,.2,0,.5,0,.7,0,.5,0,.8.6,1,.5.2.5.5,0,.7-.4.2-.5.5-.5.9v1.9c-.1,0-.2,0-.2-.1-.7-.6-1.3-1.4-1.8-2.2-.1-.2,0-.3.1-.5.2-.3.2-.4,0-.6-.1,0-.3-.2-.4-.2-.6-.1-1-.5-1.3-.9-.5-.6-1-1.2-1.4-1.8-.4-.5-.9-.8-1.5-.8-.5,0-.9-.1-1.4-.1-.6,0-1.3,0-1.9.2-.3,0-.5.2-.5.5,0,.3.2.5.4.6.2.1.5.2.7.3.5.2.5.3.4.8-.1.5,0,1,.5,1.4,0,0,.2.2.3.3.1.1,0,.2,0,.3,0,0-.1.1-.2.1-.4.2-.5.5-.6.9,0,.3,0,.6,0,1s0,.3.2.4c.2.2.3.4.3.6,0,.3,0,.6,0,.9,0,.6,0,1.1-.1,1.7,0,.6,0,1.2.8,1.4.3.1.3.2,0,.4-.5.3-.8.8-.9,1.4,0,.2,0,.2-.2.2-.2,0-.5,0-.7.1-.2,0-.4.2-.4.4,0,.2,0,.4.3.5.3,0,.5.2.8.2.6,0,1.1,0,1.7,0,.3,0,.7-.1.9-.3.2,0,.3-.3.3-.5,0-.2-.2-.3-.4-.4,0,0-.1,0-.2,0-.3,0-.5-.2-.5-.6,0-.3,0-.6.4-.7.6-.2.7-.7,0-1-.2,0-.4-.2-.4-.6-.1-1.2,0-2.5,0-3.7,0-.3,0-.5.2-.8.1,0,.2,0,.2.1,1.1.9,2.1,1.9,2.9,3.1.3.5.7.9,1,1.4.2.3.5.5.9.6.2,0,.3,0,.5.2.3.2.3.3.2.6-.2.4-.2.7,0,1,.2.4.4.7.6,1,.2.3.3.7.6.8.3.2.7,0,1.1,0,.5,0,.6,0,.7-.6,0-.4,0-.9.1-1.3,0-.4,0-.8.6-.9.2,0,.3-.3.5-.5-.2-.1-.3-.3-.5-.4-.3-.2-.5-.4-.5-.8,0-.7-.1-1.4-.1-2.1,0-.9-.3-1.8.2-2.7.2-.4.5-1,.8-1.2.2-.2.2-.3,0-.5-.2-.2-.4-.3-.6-.5-.6-.7-.4-1.6.4-1.9.4-.1.7-.2,1.1-.3.4-.1.9-.3,1.3-.5.3-.1.2-.3,0-.5Z"/>
            <path d="M114.4,13.9c-.4-.2-.5-.5-.4-.9,0-.5-.1-1-.5-1.3-.3-.3-.6-.5-.9-.8-.3-.2-.5-.5-.8-.8-.2-.2-.2-.3,0-.5.5-.4.9-.9,1.4-1.4.4-.4.8-.7,1.2-1.1.4-.3.4-.4,0-.6,0,0-.2,0-.2-.1-.1-.1-.3-.3-.4-.5-.1-.5-.3-.8-.7-1.1-.4-.3-.8-.7-1.2-1-.4-.3-.9-.5-1.3-.8-.8-.4-1.6-.3-2.4-.3-.2,0-.4,0-.5-.1-.3-.3-.6-.2-.9,0-.8.4-1.6.4-2.5.2s-.9,0-1.3-.1c-.2,0-.3,0-.2.3,0,0,0,.1,0,.2.3.8.5,1.7.4,2.6,0,.3.1.5.4.7.7.4.7.5,0,.9-.2.1-.4.3-.4.5,0,.5,0,.9,0,1.4,0,1.3.1,2.5.2,3.8,0,.4,0,.7.5.9.3.1.3.4,0,.6-.1.1-.3.2-.4.3-.3.2-.7.5-.9.8-.5.6-.3,1.2.4,1.5.5.2,1,.2,1.6.2,1.3,0,2.7-.2,4-.3h0c.8,0,1.5,0,2.3,0,1.1,0,2-.4,2.5-1.5.2-.3.4-.6.8-.7.2,0,.5-.1.6-.4,0-.3-.3-.4-.5-.5ZM111.3,14.7c-.6.2-1,.7-1.5,1.1-.3.3-.6.6-1.2.6-.3,0-.5,0-.6-.3-.2-.2-.4-.5-.7-.7-.1-.1,0-.2,0-.3.2-.1.3-.2.5-.4.4-.2.4-.5,0-.7-.6-.3-.7-.7-.7-1.3,0-1.5,0-3,0-4.5,0-.4.1-.7.5-.9.7-.4.7-.5,0-.9-.3-.2-.5-.4-.5-.8,0-.4,0-.8,0-1.1,0-.6.3-.7,1-.8h0c.5.2,1,.3,1.5.5.3.1.4.3.5.6,0,.3,0,.5,0,.8,0,.4.1.6.5.8,0,0,.2,0,.2.1.3.2.3.5,0,.6-.5.2-.8.5-1,1-.2.5-.7.8-1.3.8-.2,0-.3,0-.5,0-.2,0-.4.2-.4.4,0,.2.1.4.3.5.2.2.5.2.7.3.4.1.8.2,1.2.3.5.1.8.5.8,1,0,.5,0,1,0,1.4,0,.4.1.7.5.9.4.3.4.5,0,.7Z"/>
            <path d="M30.4,6.2s0,0,0,0c-.4-.2-.5-.4-.5-.8,0-.4,0-.9-.1-1.3,0-.3-.1-.7-.3-1-.1-.2-.3-.4-.6-.3-.7.3-1.4.2-2,0-.3,0-.6-.2-1,0-.2,0-.5,0-.8,0-.1,0-.2-.1-.3-.2-.3-.3-.2-.3-.5,0-.1.2-.3.2-.4,0,0,0,0-.1-.1-.2-.1,0-.2-.1-.3-.1,0,0-.1.2-.1.3,0,.1,0,.3,0,.5-.1-.1-.2-.2-.3-.3-.2-.2-.4-.1-.4.1,0,.2,0,.4,0,.5,0,.4,0,.5-.5.5-.4,0-.8,0-1.1.1-.3,0-.7.1-1,.2-.2,0-.4.1-.5.2,0,0-.2.2-.2.3-.2.3-.4.4-.8.4s-.4,0-.6.2c-.3.2-.4.5,0,.8,0,0,.2.1.2.2.5.3.7.5,0,.9-.3.2-.5.4-.4.8,0,.3,0,.6,0,1-.1.3-.2.6-.2.9,0,1.1.2,2.2.4,3.3,0,.6.3.9.9,1,.2,0,.4.2.6.3.2.1.2.3,0,.5-.5.5-.2,1,.2,1.4.6.5,1.3.9,2,1.2.5.2,1.1.4,1.8.4h0c.4,0,.8,0,1.2-.3.3-.2.7-.4,1.1-.4.8,0,1.4-.4,2-1,.2-.2.4-.4.6-.6.3-.3.7-.6,1.1-.7.2,0,.3-.1.5-.2.4-.2.4-.4,0-.7-.3-.2-.3-.5-.2-.8,0-.2.1-.5.2-.8,0-.5-.2-.7-.7-.4-.4.2-.6.5-.8.9-.1.4-.2.7-.3,1.1,0,.2-.1.4-.3.4-.8.4-1.6.6-2.5.7,0,0-.2,0-.3-.1-.1-.3-.4-.4-.7-.5-.3,0-.7-.2-1-.3-1-.2-1.6-.9-2-1.8-.5-1.2-.5-2.4-.3-3.7,0-.7.3-1.4,1.1-1.5,0,0,0,0,.1,0,.4-.2.4-.4,0-.7-.2-.2-.2-.3,0-.6.5-.6,1.2-1.1,1.9-1.3.2,0,.4,0,.6.1,0,0,.3.1.4.1.2,0,.4,0,.5-.1.6-.2,1.1,0,1.5.4.2.3.4.5.7.8.3.3.6.6,1,.7.1,0,.2,0,.4.2.3.2.3.3,0,.6-.2.2-.5.4-.7.7-.1.2-.3.4-.4.6-.1.3,0,.5.2.7.2.2.4,0,.5,0,.6-.2.9-.7,1.1-1.2.1-.3.3-.6.7-.7.2,0,.4-.2.4-.4,0-.2-.3-.3-.4-.4Z"/>
            <path d="M41.2,13.6s0,0,0,0c0,0,0,0,0,0h0Z"/>
            <path d="M42.5,14c-.4-.2-.9-.4-1.3-.4,0,0,0,0,0,0,.1,0,.3.2.4.3.2.2.3.3,0,.6-.2.2-.4.3-.6.4-.9.4-1.7.8-2.6,1.1-.2,0-.4,0-.6,0-.4,0-.8-.2-1.2-.3-.2,0-.3,0-.5-.2-.3-.2-.3-.6,0-.8,0,0,.2,0,.2-.1.2,0,.4-.2.3-.4,0-.1-.2-.3-.3-.4-.6-.4-.7-.5-.6-1.2,0-.4.1-.9.1-1.3,0-.4.1-.6.5-.7.1,0,.2,0,.4-.1.5,0,1,0,1.5-.1.4,0,.4,0,.5-.4,0-.2,0-.5,0-.7,0-.6-.4-.8-.9-.5-.3.1-.5.3-.7.4-.3.1-.5.2-.8.3-.3,0-.4,0-.4-.4,0-.3,0-.6,0-.9,0-.5,0-1,.6-1.2,0,0,0,0,.1,0,.2-.2.2-.3,0-.6-.1,0-.3-.1-.4-.2-.1,0-.2-.2-.3-.3-.3-.5,0-1.3.4-1.6.7-.5,1.4-.5,2.2-.3.8.2,1.4.7,1.5,1.6.1.7.7,1,1.2,1.3.2.1.5,0,.6-.2.1-.3-.1-.3-.3-.4-.2-.1-.4-.2-.4-.5,0-.3,0-.6,0-1,0-.5,0-1,0-1.5-.2-.4-.4-.5-.8-.3-.2.1-.4,0-.6,0-.3-.3-.7-.4-1.2-.3-.3,0-.6.1-.9.2-.3,0-.7.1-1,0-.4,0-.8,0-1.2,0-.4.1-.8.2-1.1.3-.7.3-1.3.2-2-.1-.2-.1-.5-.2-.8-.2-.5,0-.6.2-.5.7,0,.4.4.8.8.9.4.2.6.4.5.9,0,.4.1.6.5.8.6.4.6.5,0,.8-.3.2-.4.4-.4.8,0,.8,0,1.6,0,2.4s0,2,0,3c0,.3,0,.5.4.6,0,0,0,0,.1,0,.4.3.4.5,0,.8-.1,0-.3.2-.4.3-.1.4-.4.6-.7.9-.2.2-.3.4-.4.6-.2.4,0,.5.3.6.9.2,1.9.4,2.9.4,1.1,0,2.2,0,3.4,0,1.4,0,2.7-.2,4.1-.4.5,0,.5-.1.5-.6,0-.3,0-.7,0-1,0-.2,0-.5.3-.6,0,0,.2,0,.2-.1.3-.2.3-.4,0-.6Z"/>
            <path d="M54.6,15.7c-.2-.4,0-.7.2-.9.3-.3.3-.6-.1-.8,0,0-.1,0-.2,0-.5,0-.8-.5-.9-.9-.3-.7-.6-1.4-.9-2.1-.4-1-.9-2.1-1.3-3.1,0-.2,0-.4.2-.5.5-.3.4-.5,0-.8-.1,0-.2-.1-.3-.2-.5-.2-.8-.6-1-1-.1-.4-.2-.8-.4-1.1,0-.1-.1-.3-.2-.3-.3-.3-.6-.5-1-.8-.4-.3-.9-.3-1.2,0-.2.2-.3.4-.4.6-.3.6-.2,1.2-.2,1.8,0,.4,0,.8.5,1,.3.2.3.4,0,.5-.3.1-.5.2-.8.3-.2,0-.3.2-.4.5-.2,1.5-.6,3-1.2,4.4-.1.2-.2.5-.3.7-.1.4-.2.8.3,1.1.3.2.2.3,0,.5-.1,0-.3.2-.4.3-.2.2-.5.3-.6.6-.1.3-.3.5-.7.6-.2,0-.3.2-.3.4v.3c0,.5,0,.6.6.6.5,0,.9,0,1.4,0s.5,0,.6-.5c0-.4.1-.7.2-1.1.1-.5.4-.9,1-1,.3,0,.7,0,1-.2.2,0,.5-.2.5-.4.2-.5.5-.6.8-.6h0c.7,0,1.2.3,1.7.7.1.1.2.2,0,.4-.2.2-.4.5-.4.9s0,.2-.2.2c-.1,0-.2,0-.3,0-.6,0-.8.4-.6.9.1.4.4.5.7.7.6.2,1.2,0,1.8,0,.9-.1,1.8-.3,2.7.2.5.3.8,0,.7-.6,0-.2-.1-.3-.2-.5-.1-.3-.2-.5-.3-.8ZM48.5,12.3c-.2,0-.4,0-.6,0h0s0,0,0,0c-.3,0-.5,0-.8,0-.2,0-.2,0-.2-.3.1-.7.2-1.4.4-2.2,0-.2.1-.4.3-.6.2-.3.6-.3.8,0,.1.1.2.3.2.5.2.4.4.8.6,1.2,0,0,0,.1.1.2.3.6,0,1.1-.7,1.2Z"/>
          </g>
        </svg>
      </div>
      
      <div className="flex items-center">
        <button
          onClick={toggleInfo}
          className="h-9 p-2 text-xl rounded-full bg-zinc-200 dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 hover:bg-zinc-300 dark:hover:bg-zinc-700 focus:outline-none mr-2"
          aria-label="Information"
        >
          <MdInfoOutline size={21} />
        </button>
        <button
          onClick={toggleDarkMode}
          className="h-9 p-2 text-xl rounded-full bg-zinc-200 dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 hover:bg-zinc-300 dark:hover:bg-zinc-700 focus:outline-none"
          aria-label="Toggle Dark Mode"
        >
          {darkMode ? <MdOutlineLightMode size={21} /> : <MdOutlineDarkMode size={21} />}
        </button>
      </div>
    </header>
  );
};

export default Header;